// navbar menu
    export const navbarMenu = [
        {url:"/" , name:"Home"},
        {url:"/#courses" , name:"Courses"},
        {url:"/enquiry" , name:"Enquiry"},
        {url:"/gallery" , name:"Gallery"},
        {url:"/certification" , name:"Certification"},
        {url:"/notice" , name:"Notice"},

    ]

export const companyDetails = {
    phoneNo:"+91 6296071630",
    email:"nirvanaschoolofmusic1@gmail.com",
    address1:"Nirvana School of Music, Lane-4, Pintail Village, Dagapur,Siliguri - 734003",

}

// banner info

export const bannerInfo ={
    heading:"MUSIC EDUCATION SCHOOL",
    subHeading:"Find your creative community",
    button:"Contact us ",
    buttonTwo:"Contact us "

}

// our program

export const ourProgram = {
    heading:"Welcome to Nirvana",
    peragraph:"Established in 2016, a renowned Music Lessons & Instructions School under the board of Trinity college of London. We provide the students / learners with professional faculties, modern curriculum, with our main focus on theory of music & practical classes. Students are taught a variety of genres and styles of Music. We provide opportunities for traditional and contemporary musical expression by students. We provide students international exposure, either through competitions or workshops programs conducted overseas. They are given exposure nationally and internationally by nurturing their foundation and skills through Western Classical",
}

// why Choose nirvana

export const whyChooseUS ={
    heading:"Why Choose the ",
    peragaraph:"NIRVANA – Siliguri College of Music is a school for jazz and modern music with a worldwide reputation for the excellence of its learning program. Playing, being alive, sharing with others, learning… ever since its foundation, our school has offered students the experience of being totally immersed in their musical training.",
    button:"Learn more"

}

export const courseItem = [
    {img: "assets/icons/piano.webp",name: "Piano (Classical)", peragaraph:"Make your fingers fluent in the art of creating magical symphonies! Join our courses to explore the full spectrum of music a piano can create."},
    {img: "assets/icons/keyboard.webp",name: "Keyboard (Classical / Rock & Pop)",peragaraph: "Get educated on the concepts of holistic keyboard music, and understand and appreciate music all the while developing your musical skills."},
    {img: "assets/icons/guitar.webp",name: "Classical Guitar (Classical)", peragaraph:"Strum away the blues, Whether you are starting from scratch or an intermediate-level learner, we have a course designed for everyone!"},
    {img: "assets/icons/guitar-pick.webp",name: "Plectrum Guitar (Rock & Pop)", peragaraph:"Pluck strings like a pro! Learn how to play, control and amplify sound in a space that is welcoming and encouraging for your development."},
    {img: "assets/icons/live-music.webp",name: "Acoustic Guitar (Classical / Rock & Pop)", peragaraph:"Learn to strike the right chords and realize the beauty of a warm balanced tone of acoustic guitars. Embark on a musical journey with us!"},
    {img: "assets/icons/violin.webp",name:"Violin (Classical)",peragaraph: "Master the basics of bowing! find a community which is as curious as you are, to explore and understand the versatility of the violin"},
    {img: "assets/icons/drums.webp",name: "Drums (Rock & Pop)", peragaraph:"Passionate about rhythm and beats? Fill in your curiosity with courses that offer disciplined drum training with seasoned professionals looking out for you."},
    {img: "assets/icons/vocal.webp",name:"Western Vocals (Classical / Rock & Pop)",peragaraph: "Build your authentic musical identities with the help of interactive aids and vocal training provided by our teachers here at Nirvana School of Music"},
    {img: "assets/icons/tone.webp",name: "Theory of Music ", peragaraph:"Our curriculum emphasizes Music Theory, employing diverse teaching methods such as discussions, lectures, debates, and films. We foster emotional and mental understanding while exploring various music genres and styles"},
]


export const courseItemSelect = [
    {img: "assets/icons/piano.webp",name: "Piano (Classical)", peragaraph:"Make your fingers fluent in the art of creating magical symphonies! Join our courses to explore the full spectrum of music a piano can create."},
    {img: "assets/icons/keyboard.webp",name: "Keyboard (Classical / Rock & Pop)",peragaraph: "Get educated on the concepts of holistic keyboard music, and understand and appreciate music all the while developing your musical skills."},
    {img: "assets/icons/guitar.webp",name: "Classical Guitar (Classical)", peragaraph:"Strum away the blues, Whether you are starting from scratch or an intermediate-level learner, we have a course designed for everyone!"},
    {img: "assets/icons/guitar-pick.webp",name: "Plectrum Guitar (Rock & Pop)", peragaraph:"Pluck strings like a pro! Learn how to play, control and amplify sound in a space that is welcoming and encouraging for your development."},
    {img: "assets/icons/live-music.webp",name: "Acoustic Guitar (Classical / Rock & Pop)", peragaraph:"Learn to strike the right chords and realize the beauty of a warm balanced tone of acoustic guitars. Embark on a musical journey with us!"},
    {img: "assets/icons/violin.webp",name:"Violin (Classical)",peragaraph: "Master the basics of bowing! find a community which is as curious as you are, to explore and understand the versatility of the violin"},
    {img: "assets/icons/drums.webp",name: "Drums (Rock & Pop)", peragaraph:"Passionate about rhythm and beats? Fill in your curiosity with courses that offer disciplined drum training with seasoned professionals looking out for you."},
    {img: "assets/icons/vocal.webp",name:"Western Vocals (Classical / Rock & Pop)",peragaraph: "Build your authentic musical identities with the help of interactive aids and vocal training provided by our teachers here at Nirvana School of Music"},
    // {img: "assets/icons/tone.webp",name: "Theory of Music ", peragaraph:"Our curriculum emphasizes Music Theory, employing diverse teaching methods such as discussions, lectures, debates, and films. We foster emotional and mental understanding while exploring various music genres and styles"},
]

export const group = [
{img: "assets/one_one_class.webp", name:"One on one classes", pera:"One-on-one classes enable the learners and teachers to share a bond, allowing the students to learn at their own pace and the teachers to nurture their talents to the fullest."},
{img: "assets/group_class.webp", name:"Group classes",pera:"These classes help individuals break free from their shells, with fun jam sessions the learner learns to communicate their ideas properly and work in harmony."},
{img: "assets/online_class.webp", name:"Online class",pera:"Can’t make it to class in person? We provide a vast range of classes online as well! We maintain the quality of online classes with carefully curated classes and try to bring a learning experience one can enjoy without the hassle of commuting."},


]
export const classic = [
    {name: "A.T.C.L (Music Performance)" , pera:"Trinity's performance diplomas offer pathways to professional musicianship, emphasizing recital planning and execution. Equivalent to a first-year undergraduate recital, they teach classical instrument basics like violin, piano, and guitar."},
    {name: "L.T.C.L (Music Performance)" , pera:"Pursue professional musicianship with LTCL, akin to a first-year undergraduate recital. Craft a confident program showcasing your classical music expertise, as performance heavily influences your qualification's value."},
    {name: "AMusTCL (Music Theory)" , pera:"Trinity's music theory diplomas emphasize theoretical concepts and music history in Western classical tradition. AMusTCL is comparable to a first-year undergraduate degree, enhancing candidates' understanding of music teaching theory and practice."},
    {name: "LMusTCL (Music Theory)" , pera:"Music Theory course covers form, analysis, counterpoint, and compositional techniques, with research opportunities. LMusTCL equals final undergraduate year, assessing candidates' understanding via research, documented experience, case studies, and critical reflection."},

]
export const pop = [
    {name: "A.T.C.L (Music Performance)" , pera:"ATCL equals first-year undergraduate difficulty. Candidates learn instrument techniques crucial in rock and pop (e.g., keyboard, drums, plectrum guitar). Choose pieces from updated repertoire lists or submit own-choice selections."},
    {name: "L.T.C.L (Music Performance)" , pera:"L.T.C.L, akin to final undergraduate year, cultivates professional performance and musical acumen. Graduates emerge as versatile musicians capable of composition, arrangement, and artistic recital performance."},
    
]

export const footer = [
    {head: "Quick link", list:[
        ...navbarMenu.map(item => ({ listItem: item.name , url:item.url})),
    ]},
    {head: "Contact us", list:[
        {listItem: companyDetails.phoneNo},
        {listItem: companyDetails.email},
        {listItem: companyDetails.address1},
    ]},
]

export const admissionNotices = [
    { head: "Admissions Open for the new session 2024 - 25. ", date: "27/02/2024", category: "admission", pera: "The new admission starts from 1st of March, 2024 till 30th of April, 2024. " },
  ];

export const examNotices = [
    { head: "EXAM DATES-NOV 2024 ", date: "09/11/2024", category: "exam",  },
  ];

  export const classNotices = [
    // { head: "EXAM DATES-NOV 2024 ", date: "09/11/2024", category: "admission",  },
  ];
